* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #f9fafa;
  font-family: "Brandon Text", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Brandon Text", sans-serif;
}

/*
breakpoints: (
  sm: 392px,
  md: 768px,
  lg: 1024px,
  xl: 1440px,
);

max-widths: (
  xs: 360px,
  // plus 16px RL margin = 392
  sm: 696px,
  // plus 36px RL margin = 768
  md: 996px,
  // plus 20px RL margin = 1024
  lg: 1344px,
  // plus 48px RL margin = 1440
  xl: 1344px,
);
*/

@font-face {
  font-family: "Brandon Text";
  font-weight: 300;
  src: url("./fonts/BrandonText-Light.otf");
}

@font-face {
  font-family: "Brandon Text";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/BrandonText-Regular.otf");
}

@font-face {
  font-family: "Brandon Text";
  font-weight: 500;
  src: url("./fonts/BrandonText-Medium.otf");
}

@font-face {
  font-family: "Brandon Text";
  font-style: bold;
  font-weight: 700;
  src: url("./fonts/BrandonText-Bold.otf");
}

@font-face {
  font-family: "Brandon Text";
  font-weight: 900;
  src: url("./fonts/BrandonText-Black.otf");
}

html {
  --white: #ffffff;
  --brandGreen: #17da5e;
  --brandGreenRGB: 23, 218, 94;
  --brandGreenDark: #14c756;
  --errorPink: #ef4b54;
  --errorPinkBG: rgba(239, 75, 84, 0.05);
  --grey12: #263238;
  --grey11: #37474f;
  --grey10: #455a64;
  --grey09: #546e7a;
  --grey08: #607d8b;
  --grey07: #78909c;
  --grey06: #90a4ae;
  --grey05: #b0bec5;
  --grey04: #cfd8dc;
  --grey03: #e7edf1;
  --grey02: #f5f8fa;
  --grey01: #f8fafa;
}
